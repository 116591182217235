import React, { useState } from 'react'
import Image from 'next/image'

import styles from './Button.module.scss'

type ButtonType = {
  theme: string
  width?: string
  children: any
}

const Button = (props: ButtonType) => {
  const { theme, width, children } = props
  const [isHover, setIsHover] = useState(false)
  const className = `${styles.button} ${styles[theme]}`

  const arrowSrc = (theme: string, hover: boolean) => {
    const dark = '/arrow-black.svg'
    const light = '/arrow-white.svg'

    if (theme == 'dark') {
      if (hover) {
        return light
      }
      return dark
    }
    if (hover) {
      return dark
    }
    return light
  }

  return (
    <button
      className={className}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <span className={styles.arrow}>
        <Image src={arrowSrc(theme, isHover)} layout="fill" alt="" loading="eager"/>
      </span>
      <span className={`${styles.text} ${width && styles[width]}`}>
        {children}
      </span>
    </button>
  )
}

export default Button
