import React from 'react'
import Head from 'next/head'
import { useGlobalContentContext } from '../../../context/GlobalContentContext'

export type CustomMetaDataProps = {
  title?: string
  description?: string
  image?: string
}

const CustomMetaData = (props: CustomMetaDataProps) => {
  const { title, description, image } = props
  const { data } = useGlobalContentContext()
  let meta_image = image

  if (!image && data.site_meta) {
    meta_image = image ? image : data.site_meta.meta_image
  }
  const full_title = title ? `${title} | Sakuu` : 'Sakuu'

  return (
    <Head>
      <title>{full_title}</title>
      <meta property="og:title" content={full_title} key="title" />
      {description && (
        <meta
          property="og:description"
          content={description}
          key="description"
        />
      )}
      {meta_image && <meta property="og:image" content={meta_image} key="image" />}
    </Head>
  )
}

export default CustomMetaData
