import React, { useState, useEffect } from 'react'
import Image, { ImageProps } from 'next/image'
import { useIsMobile, useIsDesktop } from '../../hooks/useIsDesktop'

import styles from './CustomImage.module.scss'

export type CustomImageProps = {
  src: {
    image: string
    image_width: string
    image_height: string
    mobile_image?: string
    mobile_image_width?: string
    mobile_image_height?: string
    alt_text?: string
  }
  loadedCallback?: () => void
} & ImageProps

const CustomImage = ({ ...props }: CustomImageProps) => {
  const phoneImageSrc = props.src.mobile_image
  const phoneWidth = props.src.mobile_image_width
    ? parseInt(props.src.mobile_image_width)
    : undefined
  const phoneHeight = props.src.mobile_image_height
    ? parseInt(props.src.mobile_image_height)
    : undefined
  const alt = props.src.alt_text

  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const [src, setSrc] = useState<string | undefined>(undefined)
  const [width, setWidth] = useState<number | undefined>(undefined)
  const [height, setHeight] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (isMobile && phoneImageSrc && containerRef.current) {
      setSrc(phoneImageSrc)
    } else if (isDesktop || !phoneImageSrc) {
      setSrc(props.src.image)
    }
  }, [isMobile, isDesktop, phoneImageSrc, props.src.image])

  const handleImageLoad = () => {
    if (containerRef.current) {
      containerRef.current.classList.add('image-loaded')
      containerRef.current.classList.add(styles.imageLoaded)
    }
    if (props.loadedCallback !== undefined) {
      props.loadedCallback()
    }
  }

  useEffect(() => {
    if (props.layout === 'fill') {
      setWidth(undefined)
    } else if (isMobile && phoneImageSrc && phoneWidth) {
      setWidth(phoneWidth)
    } else if (isDesktop || !phoneImageSrc) {
      setWidth(parseInt(props.src.image_width))
    }
  }, [
    isMobile,
    isDesktop,
    phoneImageSrc,
    src,
    props.layout,
    props.src.image_width,
    phoneWidth,
  ])

  useEffect(() => {
    if (props.layout === 'fill') {
      setHeight(undefined)
    } else if (isMobile && phoneImageSrc && containerRef.current) {
      setHeight(phoneHeight)
    } else if (isDesktop || !phoneImageSrc) {
      setHeight(parseInt(props.src.image_height))
    }
  }, [
    isMobile,
    isDesktop,
    phoneImageSrc,
    src,
    props.layout,
    props.src.image_height,
    phoneHeight,
  ])

  return (
    <span ref={containerRef}>
      {src && (
        <Image
          className={`responsive-image ${phoneImageSrc && styles.hasMobilSrc}`}
          src={src}
          alt={props.alt}
          layout={props.layout}
          loading="eager"
          objectFit={props.objectFit}
          width={width}
          height={height}
          onLoadingComplete={handleImageLoad}
        />
      )}
    </span>
  )
}

export default CustomImage
