import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { createMarkup } from '../../utils/helpers'
import CustomImage from '../customImage/CustomImage'
import { useIsMobile, useIsDesktop } from '../../hooks/useIsDesktop'
import { useHasMounted } from '../../hooks/useHasMounted'


import styles from './News.module.scss'

export type NewsProps = {
  item: any
  showPubDate?: boolean
  showPubDateTop?: boolean
  showDescription?: boolean
  showReadMore?: boolean
}

const NewsPreviewItem = ({ ...props }: NewsProps) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const hasMounted = useHasMounted()

  const date = new Date(props.item.pub_time)
  const formattedDate = `${String(date.getMonth() + 1).padStart(
    2,
    '0'
  )}.${String(date.getDate()).padStart(2, '0')}.${date
    .getFullYear()
    .toString()
    .slice(-2)}`
  return (
    <div className={styles.item}>
      <Link
        href={{
          pathname: '/news/[slug]',
          query: { slug: props.item.slug },
        }}
      >
        <a>
          {props.showPubDateTop && isDesktop && hasMounted && (
            <div className={styles.pubDate}>{formattedDate}</div>
          )}
          <div className={styles.imageContainer}>
            <CustomImage src={props.item} layout="fill" objectFit="cover" />
          </div>

          {props.item.category && (
            <h5 className={styles.category}>{props.item.category.title}</h5>
          )}
          {props.showPubDateTop && isMobile && (
            <div className={styles.pubDate}>{formattedDate}</div>
          )}

          {props.showPubDate && hasMounted && (
            <div className={styles.pubDate}>{formattedDate}</div>
          )}
          <h3
            className={props.showDescription ? styles.title : styles.titleOnly}
            dangerouslySetInnerHTML={{__html: props.item.title}}

          >
          </h3>
          {props.showDescription && (
            <div
              className={styles.detail}
              dangerouslySetInnerHTML={createMarkup(props.item.short_detail)}
            ></div>
          )}
          {props.showReadMore && (
            <div className={styles.readMore}>Read More</div>
          )}
        </a>
      </Link>
    </div>
  )
}

export default NewsPreviewItem
