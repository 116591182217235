import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import Button from '../button/Button'
import CustomImage from '../customImage/CustomImage'

import styles from './Careers.module.scss'
import { createMarkup } from '../../utils/helpers'

type CareersProps = {
  careersSection: any
}

const Careers = (props: CareersProps) => {
  const { careersSection } = props
  return (
    <section className={styles.section}>
      <h2 className={styles.title}>{careersSection.title}</h2>
      <div className={styles.imageContainer}>
        <CustomImage
          className={styles.careerImage}
          src={careersSection}
          layout="responsive"
        />
      </div>
      <div
        className={styles.detail}
        dangerouslySetInnerHTML={createMarkup(careersSection.detail)}
      ></div>
      <Link href="/careers">
        <a>
          <Button theme="dark" width="wide">
            Find your spot
          </Button>
        </a>
      </Link>
    </section>
  )
}

export default Careers
