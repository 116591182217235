import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import type { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import Button from '../components/button/Button'
import Grid from '../components/grid/Grid'
import Careers from '../components/careers/Careers'
import FadeIn from '../components/fadeIn/FadeIn'
import CustomImage from '../components/customImage/CustomImage'
import styles from '../styles/pages/Home.module.scss'

import CustomMetaData from '../components/general/customMetaData/CustomMetaData'
import { GenericPageLayoutProps } from '../components/general/genericPageLayout/GenericPageLayout'

import { useGlobalContentContext } from '../context/GlobalContentContext'
import NewsPreviewItem, { NewsProps } from '../components/news/News'

import { parseGenericPage } from '../utils/parsers'
import { getAPI, createMarkup } from '../utils/helpers'
import { widont } from '../utils/typography'

import { useWindowSize, useIsomorphicLayoutEffect } from 'usehooks-ts'
import { useIsDesktop, useIsMobile } from '../hooks/useIsDesktop'
import { useHasMounted } from '../hooks/useHasMounted'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import variables from '../styles/_constants.module.scss'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

if (typeof window !== 'undefined') {
  // Due to SSR we can only register when we are in the browser
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(ScrollToPlugin)
}

type HomeProps = {
  news: any
} & GenericPageLayoutProps

const Home = (props: HomeProps) => {
  const { setData } = useGlobalContentContext()
  const hasMounted = useHasMounted()
  const { width, height } = useWindowSize()
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const heroContainerRef = useRef<HTMLDivElement>(null)

  const [offsetWidth, setOffsetWidth] = useState<number>(0)
  const [slideMargin, setSlideMargin] = useState<number>(0)

  useIsomorphicLayoutEffect(() => {
    if (!isMobile) {
      setOffsetWidth(width / 6)
    } else {
      setOffsetWidth(10)
    }
  }, [width, isMobile])

  const heroImageRef = useRef<HTMLDivElement>(null)
  const heroImageFixed = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSlideMargin(
      isMobile
        ? 10
        : (parseFloat(variables.desktopMargin) *
            parseInt(variables.rootFontBaseSize) *
            width) /
            parseInt(variables.designWidth)
    )
  }, [width, isMobile])

  useEffect(() => {
    setData(props.globalData)
  }, [props.globalData, setData])

  useIsomorphicLayoutEffect(() => {
    if (
      heroImageRef.current &&
      heroImageFixed.current &&
      heroContainerRef.current
    ) {
      gsap.set(heroImageRef.current, {
        autoAlpha: 0.6,
      })
      const t1 = gsap.timeline({
        scrollTrigger: {
          trigger: heroContainerRef.current,
          start: 'top top',
          end: `+=${Math.abs(height * 0.75)}`,
          scrub: 1,
        },
      })

      t1.fromTo(
        heroImageRef.current,
        {
          autoAlpha: 0.6,
        },
        {
          autoAlpha: 1,
          duration: 1,
          ease: 'power1.in',
        }
      )
      return () => {
        t1 && t1.kill()
      }
    }
  }, [heroImageRef, heroImageFixed, height])

  return (
    <div className={styles.container}>
      <CustomMetaData
        title={props.originalResponse.meta_title}
        description={props.originalResponse.meta_description}
        image={props.originalResponse.meta_image}
      />

      <div className={styles.main}>
        <section className={styles.hero} ref={heroContainerRef}>
          <div className={styles.heroFixed} ref={heroImageFixed}>
            <div className={styles.heroImage} ref={heroImageRef}>
              <CustomImage
                src={props.originalResponse.hero}
                layout="fill"
                objectFit="cover"
                priority
              />
            </div>
          </div>
          <div
            className={`${styles.maxWidthContainer} ${styles.heroContentContainer}`}
          >
            <div className={styles.heroContent}>
              <h1
                className={styles.heroTitle}
                dangerouslySetInnerHTML={createMarkup(
                  props.originalResponse.hero.title
                )}
              ></h1>

              <div
                className={styles.heroDetail}
                dangerouslySetInnerHTML={createMarkup(
                  props.originalResponse.hero.detail
                )}
              ></div>
            </div>
            <div className={styles.heroLink}>
              <Link href="/contact">
                <a>
                  <Button theme="light">Contact Us</Button>
                </a>
              </Link>
            </div>
          </div>
        </section>
        <div className={styles.contentContainer}>
          <section className={styles.aboutImageSection}>
            <div className={styles.aboutImageContainer}>
              <CustomImage
                src={props.originalResponse.aboutimagesection}
                layout="fill"
                objectFit="cover"
              />
              <div className={styles.relativeMaxWidthContainer}>
                <div className={styles.aboutImageContent}>
                  <h2
                    className={styles.aboutImageTitle}
                    dangerouslySetInnerHTML={{
                      __html: widont(
                        props.originalResponse.aboutimagesection.title
                      ),
                    }}
                  ></h2>
                  <div
                    className={styles.aboutImageDetail}
                    dangerouslySetInnerHTML={createMarkup(
                      props.originalResponse.aboutimagesection.detail
                    )}
                  ></div>

                  <Link href="/manufacturing">
                    <a>
                      <Button theme="light">Learn More</Button>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className={styles.sustainabilitySection}>
            <div className={styles.sustainabilityImageContainer}>
              <CustomImage
                src={props.originalResponse.sustainability}
                layout="fill"
                objectFit="cover"
              />
              <div className={styles.relativeMaxWidthContainer}>
                <div className={styles.sustainabilityImageContent}>
                  <h2
                    className={styles.sustainabilityImageTitle}
                    dangerouslySetInnerHTML={{
                      __html: widont(
                        props.originalResponse.sustainability.title
                      ),
                    }}
                  ></h2>
                  <div
                    className={styles.sustainabilityImageDetail}
                    dangerouslySetInnerHTML={createMarkup(
                      props.originalResponse.sustainability.detail
                    )}
                  ></div>

                  <Link href="/manufacturing">
                    <a>
                      <Button theme="light">Learn More</Button>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className={styles.aboutContainer}>
            <div className={styles.aboutTitle}>
              {props.originalResponse.about.title}
            </div>

            <div
              className={styles.aboutDetail}
              dangerouslySetInnerHTML={createMarkup(
                props.originalResponse.about.detail
              )}
            ></div>

            <div className={styles.aboutLink}>
              <Link href="/batteries">
                <a>
                  <Button theme="dark">Learn More</Button>
                </a>
              </Link>
            </div>

            <div className={styles.aboutImage}>
              <CustomImage
                src={props.originalResponse.about}
                layout="responsive"
              />
            </div>

            <div
              className={styles.aboutFootNote}
              dangerouslySetInnerHTML={createMarkup(
                props.originalResponse.about.shipping
              )}
            ></div>
          </section>

          {/* <section className={styles.batteriesContainer}>
            <div className={styles.maxWidthContainer}>
              <FadeIn>
                <h2 className={styles.batteriesTitle}>
                  {props.originalResponse.about.batteries_title}
                </h2>

                <div
                  className={styles.batteriesDetail}
                  dangerouslySetInnerHTML={createMarkup(
                    props.originalResponse.about.batteries_detail
                  )}
                ></div>

                <div className={styles.batteriesLink}>
                  <Link href="/industries">
                    <a>
                      <Button theme="dark">Learn More</Button>
                    </a>
                  </Link>
                </div>
              </FadeIn>
            </div>
            <div className={styles.aboutItems}>
              {hasMounted && (
                <Swiper
                  breakpoints={{
                    678: {
                      slidesPerGroup: 2,
                    },
                    2200: {
                      slidesPerGroup: 3,
                    },
                  }}
                  spaceBetween={slideMargin}
                  slidesPerView="auto"
                  slidesPerGroupAuto={true}
                  slidesOffsetBefore={offsetWidth}
                  loop={true}
                  navigation={true}
                  modules={[Navigation]}
                >
                  {props.originalResponse.about_items.map((item: any) => (
                    <SwiperSlide
                      className={styles.swiperSlideContainer}
                      key={item.id}
                      virtualIndex={item.id}
                    >
                      <div className={styles.swiperSlide}>
                        <CustomImage src={item} layout="responsive" />
                      </div>
                      <div className={styles.swiperTitle}>{item.title}</div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </section>

          <section className={styles.sustainabilitySection}>
            <div className={styles.sustainabilityImageContainer}>
              <CustomImage
                src={props.originalResponse.sustainability}
                layout="fill"
                objectFit="cover"
              />
              <div className={styles.relativeMaxWidthContainer}>
                <div className={styles.sustainabilityImageContent}>
                  <h2
                    className={styles.sustainabilityImageTitle}
                    dangerouslySetInnerHTML={{
                      __html: widont(
                        props.originalResponse.sustainability.title
                      ),
                    }}
                  ></h2>
                  <div
                    className={styles.sustainabilityImageDetail}
                    dangerouslySetInnerHTML={createMarkup(
                      props.originalResponse.sustainability.detail
                    )}
                  ></div>

                  <Link href="/sustainability">
                    <a>
                      <Button theme="light">Learn More</Button>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </section> */}

          <section className={`${styles.newsSection} news-button`}>
            <h2 className={styles.newsTitle}>Latest News</h2>
            {hasMounted && (
              <>
                {isDesktop && (
                  <Grid columns={4}>
                    {props.news
                      .filter((item: any) => item.pub_status === 30)
                      .filter((item: any) => item.featured)
                      .slice(0, 4)
                      .map((item: any, index: number) => (
                        <NewsPreviewItem
                          key={index}
                          item={item}
                          showDescription={false}
                          showPubDate={true}
                          showReadMore={true}
                        />
                      ))}
                  </Grid>
                )}
                {isMobile && (
                  <Swiper
                    spaceBetween={slideMargin}
                    slidesPerView="auto"
                    slidesOffsetBefore={0}
                    loop={true}
                    navigation={true}
                    modules={[Navigation]}
                  >
                    {props.news
                      .filter((item: any) => item.featured)
                      .slice(0, 4)
                      .map((item: any, index: number) => (
                        <SwiperSlide
                          className={styles.swiperSlideContainer}
                          key={index}
                          virtualIndex={item.id}
                        >
                          <NewsPreviewItem
                            key={index}
                            item={item}
                            showDescription={false}
                            showPubDate={true}
                            showReadMore={true}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                )}
              </>
            )}
          </section>

          {props.originalResponse.careerssection && (
            <Careers careersSection={props.originalResponse.careerssection} />
          )}
        </div>
      </div>
    </div>
  )
}

export async function getStaticProps() {
  const page: GenericPageLayoutProps = await parseGenericPage(
    `${getAPI()}/homepage/`
  )

  const newsRequest = await fetch(`${getAPI()}/news-articles/`)
  const news = await newsRequest.json()

  return {
    props: {
      originalResponse: page.originalResponse,
      globalData: page.globalData,
      title: page.title,
      news: news,
    },
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS,
  }
}

export const NEXTJS_REVALIDATE_PAGE_IN_SECONDS = 10
export default Home
