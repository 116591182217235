import React from 'react'
import styles from './Grid.module.scss'

type GridType = {
  columns: number
  children: any
  gap?: string
}

type StyleOptions = {
  gridTemplateColumns: string
  gap?: string
}

const Grid = (props: GridType) => {
  const { columns, children } = props

  let stylesOptions: StyleOptions = {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  }

  if (props.gap) {
    stylesOptions.gap = `${props.gap}px`
  }

  return (
    <div className={styles.container} style={stylesOptions}>
      {children}
    </div>
  )
}

export default Grid
